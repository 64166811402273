import {Box, Center, Checkbox, FormControl, Link, Text} from 'native-base';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {RemoteConfigContext} from '../hooks/SessionContext';
import {fonts} from '../styles/fonts';
import IconFont from './Icon/IconFont';
import {isWeb} from '../helpers/utils/utils';
import {getWidthAndHeight} from '../helpers/utils/utils';

/**
 * Componente TermsAndConditions que aparece en el Registro.
 * @param setTerms onChange
 */

const TermsAndConditions = ({setTerms, testID}) => {
  const {t, i18n} = useTranslation();
  const {width} = getWidthAndHeight();
  const {
    remoteConfig: {urls},
  } = useContext(RemoteConfigContext);

  return (
    <Center>
      <FormControl w={'100%'}>
        <Checkbox.Group
          w={'100%'}
          testID={testID + '-group'}
          accessibilityLabel="choose numbers">
          <Checkbox
            testID={testID}
            value={'uno'}
            colorScheme="white"
            icon={
              <Box
                justifyContent="center"
                alignItems="center"
                bg={'primary.100'}>
                <IconFont name="square" size={12} />
              </Box>
            }
            onChange={setTerms}>
            <Box>
              <Box
                w={isWeb() && width < 500 ? '70%' : '100%'}
                color={'primary.100'}
                flexDir={'row'}
                flexWrap={'wrap'}
                fontWeight={600}
                alignItems={'center'}
                ml={1}>
                <Link
                  testID="link-terms"
                  href={
                    urls.termsAndConditionsT[i18n.language] ||
                    urls.termsAndConditions
                  }
                  isExternal
                  isUnderlined={false}
                  _text={{
                    fontFamily: fonts.quicksand,
                    color: 'primary.100',
                    fontSize: 'sm',
                    fontWeight: 500,
                    underline: true,
                  }}
                  mt={-0.5}
                  mb={0}
                  _web={{mb: -2}}>
                  {t('login:termsAndConditions.termsAndConditions')}
                </Link>
                <Text
                  fontFamily={fonts.quicksand}
                  fontWeight={500}
                  fontSize={'sm'}
                  mt={2}
                  color={'tertiary.100'}>
                  {t('login:termsAndConditions.and')}
                </Text>
                <Link
                  testID="link-privacy"
                  href={urls.privacyT[i18n.language] || urls.privacy}
                  isExternal
                  isUnderlined={false}
                  _text={{
                    fontFamily: fonts.quicksand,
                    color: 'primary.100',
                    fontSize: 'sm',
                    fontWeight: 500,
                    underline: true,
                  }}
                  mt={-0.5}
                  mb={0}
                  _web={{mb: -2}}>
                  {t('login:termsAndConditions.privacyNotice')}
                </Link>
              </Box>
            </Box>
          </Checkbox>
        </Checkbox.Group>
      </FormControl>
    </Center>
  );
};

TermsAndConditions.propTypes = {
  setTerms: PropTypes.func.isRequired,
};

export default TermsAndConditions;
