import {Box, HStack, Text} from 'native-base';
import React, {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import AnimatableBox from '../AnimatableBox/AnimatableBox';
import PrimaryButton from '../PrimaryButton';
import FeedbackCard from './FeedbackCard';

const ConversationCard = forwardRef((props, ref) => {
  const {t} = useTranslation();
  const {
    id,
    chatStateResponse,
    onStartConversation,
    recommendationHappened,
    extraData,
  } = props;

  const {title, conversation_flow_name, description} =
    extraData?.display_details;

  return (
    <AnimatableBox testID={'bot-flow'} key={'video-message-' + id} ref={ref}>
      <Box borderRadius={20} bg={'linen.20'} p={'6%'}>
        <Text
          color={'tertiary.100'}
          fontWeight={800}
          textAlign={'right'}
          alignSelf={'flex-end'}>
          {t('exercises:chat')}
        </Text>

        <HStack justifyContent={'space-between'}>
          <Text color={'primary.100'} fontSize={'md'} fontWeight={800}>
            {title}
          </Text>
          {/* type === MESSAGE_TYPES.BOT_EXERCISE_VIDEO && <Text>Pa guardar</Text> */}
        </HStack>

        <Box flex={1}>
          <Text my={2} color={'tertiary.100'} fontSize={'sm'}>
            {description}
          </Text>
          {recommendationHappened && chatStateResponse === 'FINISH_MESSAGE' ? (
            <Box testID={'flow-feedback-card'}>
              <FeedbackCard contentType={'flow'} {...props} />
            </Box>
          ) : (
            <PrimaryButton
              testId={'start-conversation-flow'}
              extraParams={{
                isDisabled: recommendationHappened,
              }}
              onPress={() => onStartConversation(conversation_flow_name)}>
              {t('chat:startExercise')}
            </PrimaryButton>
          )}
        </Box>
      </Box>
    </AnimatableBox>
  );
});

export default ConversationCard;
