export default {
  placeholder: 'Escribe tu mensaje',
  placeholderProgram: '¿Qué podría mejorar?',
  messageNotRead: 'mensaje no leído',
  messagesNotRead: 'mensajes no leídos',
  registerMood: 'Registrar estado de ánimo',
  chooseTheme: 'Elegir un tema',
  help: 'Ayuda',
  settings: 'Ajustes',
  logout: 'Cerrar sesión',
  noThemes: 'No cuentas con temas a seleccionar',
  send: 'Enviar',
  chatIntro1:
    'Vas a entrar a un chat en el que podrás platicar más libremente conmigo.',
  chatIntro2:
    'Te haré algunas preguntas para que poco a poco comprendas más tus emociones, ¿vale?',
  conversationHistoryTitle: 'Conversación del {{date}}',
  aiAboutSubTitle: 'Mindsurf + AI',
  aiAboutButton: 'Entendido',
  feedbackButton: 'Enviar',
  aboutAssistantTitle: 'Acerca del asistente',
  sessionTherapy: 'Sesión con un terapeuta',
  explore: 'Explorar red',
  seeProgram: 'Ver programa',
  startEvaluation: 'Ver más',
  startExercise: 'Comenzar ejercicio',
  feedback: '¿Este ejercicio te ayudó a que te sintieras mejor?',
  feedbackProgram: '¿Esta sesión te ayudo a mejorar tu bienestar emocional?',
  sessions: 'sesiones',
  answersChat: 'Algunas respuestas:',
  backHome: 'Terminar sesión',
  chatVoice: 'Platiquemos por voz',
  chatVoiceDesc:
    'Ahora puedes dictar por voz lo que quieres hablar con tu asistente emocional. ¿Te gustaría intentarlo?',
  useVoice: 'Usar voz',
  useText: 'Usar chat',
  new: 'NUEVO',
  authorize: 'Deberás autorizar el uso de tu micrófono.',
  emotionalAssistant: 'Asistente emocional',
  modalVoice: 'Mantén presionado para hablar.',
  cancelMessage: '← Arrastra para cancelar',
  byeButton: 'Hasta luego',
  cancelButton: 'Cancelar',
  alertDevice: 'Dispositivo no compatible',
  alertDeviceDesc: 'Tu dispositivo no es compatible para esta funcionalidad',
  permissionMicro: 'Permiso de micrófono requerido',
  permissionMicroDesc:
    'Necesitas brindar permiso para micrófono desde Configuración.',
  modalInfoBoxAssistant:
    'Aquí puedes hablar con el asistente emocional de Mindsurf sobre cómo te sientes. No importa si tu día fue bueno o malo, siempre encontrará la forma de ayudarte con ejercicios y reflexiones.\n' +
    '\n' +
    'Cuéntale a través de mensajes de texto. Solo considera que entre más hables con él, mejores recomendaciones podrá darte.',
  emergencyChat: 'Chat de emergencia',
  voiceStreamingTitle: 'Llamada a asistente emocional',
  voiceMessagePermissions:
    'Habla con el asistente en una llamada. Al comenzar, escucharás su voz y podrás contestar sin presionar botones. \n\n Deberás autorizar el uso de tu micrófono.',
  conectionTypeMessages:
    '\nPara hablar por voz con el asistente, tu celular debe estar conectado a 4G, 5G o wifi.',
  startButton: 'Comenzar',
  voiceMessageBlockedTitle: 'Permiso de micrófono requerido',
  voiceMessageBlocked:
    '¡Ups! Parece que has bloqueado el acceso al micrófono. Para disfrutar de esta función, por favor, desbloquea los permisos en la configuración de tu teléfono. Haz clic en el botón a continuación para ir directamente a la configuración.',
  voiceMessageBlockedButton: 'Ir a configuración',
  voiceMessageBlockedButtonCancel: 'Cancelar',
  titleBanner: 'Soy tu asistente emocional.',
  messageBanner: '¿Quieres platicar o desahogarte?',
  callFinished: 'La llamada terminó.',
  callError: 'Hubo un error al realizar la llamada.',
  notInternetReachable:
    '¡Ups! Parece que no tienes acceso a internet. Para disfrutar de esta función, por favor verifica tu conexión.',
  recording1: 'Comienza a hablar',
  recording2: 'Escuchando',
  recording3: 'Termina de hablar para enviar el mensaje o presiona Enviar.',
  processing1: 'Pensando',
  processing2: 'Reflexionando, estoy contigo en un momento.',
  processing3: 'Procesando, estoy aquí para ayudar.',
  notTranscription: 'No se oye nada… Vuelve a intentarlo.',
  reconnecting: 'Reconectando. Por favor, espera.',
  notReconnected: 'Tuvimos un problema. Reintenta más tarde.',
  finish: 'Finalizar',
};
