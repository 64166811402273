import React, {useState} from 'react';
import {Box, Text, Center, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import CoachCardButton from '../Excercise/CoachCardButton';
import {getFormatShortDate, isWeb} from '../../helpers/utils/utils';

const CardExcercise = ({
  children,
  data,
  contentPosition = 'after',
  showDescription = true,
}) => {
  const {t, i18n} = useTranslation();
  const [lang] = useState(i18n.language || 'es');
  const lastRecord =
    data?.records?.[0] && data?.records?.[0]?.updatedAt
      ? getFormatShortDate(
          data?.records?.[0] && data?.records?.[0]?.updatedAt,
          i18n?.language,
        )
      : '';

  const contentTypeTranslations = {
    AUDIO: lang === 'es' ? 'Audio' : 'Audio',
    FLOW: lang === 'es' ? 'Chat' : 'Chat',
    evaluation_v2: lang === 'es' ? 'Evaluación' : 'Evaluation',
    Video: lang === 'es' ? 'Video' : 'Video',
    PROGRAMA_V2_ACTIVITY: lang === 'es' ? 'programa' : 'program',
    liveSession: lang === 'es' ? 'Sesión en vivo' : 'Live session',
    videoPost: lang === 'es' ? 'Sesión en vivo' : 'Live session',
    ARTICLE: lang === 'es' ? 'Artículo' : 'Article',
    session: lang === 'es' ? 'Sesión' : 'Session',
  };
  const contentTypeText = contentTypeTranslations[data?.contentType] || '';
  return (
    <VStack testID="card-info">
      <Center px={5} pt={3} pb={2}>
        <Text
          textAlignVertical={'flex-start'}
          textAlign={'center'}
          fontWeight={800}
          fontFamily={'recoleta'}
          adjustsFontSizeToFit={true}
          numberOfLines={3}
          fontSize={'2xl'}>
          {data?.title}
        </Text>
      </Center>
      <Center pb={3}>
        <Text
          textAlign={'center'}
          fontWeight={800}
          fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
          fontSize={'sm'}
          color={'tertiary.100'}>
          {contentTypeText} •{' '}
          {t('introex:minutes', {
            duration: data.duration || data?.programSession?.duration,
          })}
        </Text>
      </Center>
      <CoachCardButton coach={data?.coach} />
      {/* MainContent */}
      {contentPosition === 'before' && children}
      {showDescription && (
        <Box pl={3}>
          <Text
            color={'primary.100'}
            fontSize={'lg'}
            fontWeight={800}
            my={3}
            fontFamily={'recoleta'}>
            {t('introex:descriptionLabel')}
          </Text>
          <Text color={'primary.100'} fontSize={'md'} numberOfLines={7} mr={2}>
            {data?.shortDescription || data?.description}
          </Text>
        </Box>
      )}
      {data?.records?.length > 0 && (
        <Center>
          <Text
            fontWeight={800}
            fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
            color={'tertiary.100'}>
            {`${t('introex:lastInteraction')}${lastRecord}`}
          </Text>
        </Center>
      )}
      {/* MainContent */}
      {contentPosition === 'after' && children}
    </VStack>
  );
};

export default CardExcercise;
