import {Box, HStack, Text} from 'native-base';
import PropTypes from 'prop-types';
import React, {forwardRef, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getContentUrlConfig} from '../../helpers/chatUtils';
import AnimatableBox from '../AnimatableBox/AnimatableBox';
import AudioTrack from '../AudioTrack/AudioTrack';
import RatingExercise from '../../components/Exercises/RatingExercise';
import usePrivileges from '../../hooks/useUserSubscriptionAccess';
import {MODULES, isModuleActive} from '../../core/featureFlagService';
import {FeatureFlagsContext} from '../../hooks/SessionContext';

/**
 * Componente  AudioMessage normal
 */

const AudioMessage = forwardRef((props, ref) => {
  const {content, onFinish, onStart, id, extraData, sendFeedback} = props || {};
  const [isVisibleFeedback, setIsVisibleFeedback] = useState(false);
  const [showFeedback, setShowFeedback] = useState(true);

  const {featureFlags} = useContext(FeatureFlagsContext);

  const {t} = useTranslation();

  if (!content) return null;
  const urlStart = content.indexOf('https');

  let text = '';
  let url = '';
  let duration = 0;

  if (urlStart === -1) {
    // take url from extradata
    text = extraData?.display_details?.title;
    url = extraData?.display_details?.content_url;
    const durationInSecs = extraData?.display_details?.duration_in_secs;
    duration = extraData?.display_details?.duration;
    duration =
      durationInSecs > 60
        ? durationInSecs
        : duration * 60 + (durationInSecs || 0);
  } else {
    const getContentURL = getContentUrlConfig({message: content});
    url = getContentURL?.url;
    text = getContentURL?.text;
    duration = getContentURL?.duration;
  }

  const extraParams = {
    exercise_program_title: text,
  };

  return (
    <AnimatableBox
      animation={'fadeInLeftBig'}
      testID={'bot-audio'}
      key={'audio-message-' + id}
      ref={ref}>
      <Box borderRadius={20} bg={'babyBlue.500'} p={'6%'}>
        <Text
          color={'tertiary.100'}
          fontWeight={800}
          textAlign={'right'}
          alignSelf={'flex-end'}>
          {t('exercises:audio')}
        </Text>

        <HStack justifyContent={'space-between'}>
          <Text color={'primary.100'} fontSize={'md'} fontWeight={800}>
            {text}
          </Text>
        </HStack>
        <Box px={3} py={5}>
          <AudioTrack
            title={text}
            id={'audio-' + id}
            contentUrl={url}
            duration={duration}
            onFinish={onFinish}
            destroyOnUnmount={true}
            setIsVisible={setIsVisibleFeedback}
            showFeedback={showFeedback}
            onStart={() => onStart(text)}
          />
        </Box>
        {isVisibleFeedback &&
          isModuleActive(MODULES.RAITING_EXERCISE, featureFlags) && (
            <RatingExercise
              sendFeedback={(stars, comment) => {
                sendFeedback(stars, comment, extraParams);
                setIsVisibleFeedback(false);
                setShowFeedback(false);
              }}
              isMessage={true}
              testID={'rating-audio-message'}
            />
          )}
      </Box>
    </AnimatableBox>
  );
});

AudioMessage.propTypes = {
  content: PropTypes.object,
  onFinish: PropTypes.func,
  onStart: PropTypes.func,
};
export default AudioMessage;
