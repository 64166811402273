import {URLS} from './urls';

const latestVersion = {
  ios: {recommended: 101, needed: 99},
  android: {recommended: 98, needed: 94},
};

const profiling = {
  profile: [
    {
      name: 'nombres',
      type: 'String',
      validation: 'required',
      copy: 'onboarding:inputProfilingName',
    },
    {
      name: 'birthDate',
      type: 'Date',
      grapqhType: 'date',
      copy: 'onboarding:inputProfilingBirthDate',
    },
    {
      name: 'gender',
      type: 'Select',
      validation: 'required',
      numColumns: [2, 2],
      options: [
        {
          key: 1,
          value: 'onboarding:selectGenderFemale',
          w: '50%',
        },
        {
          key: 2,
          value: 'onboarding:selectGenderMale',
          w: '50%',
        },
        {
          key: 3,
          value: 'onboarding:selectGenderNon',
          w: '50%',
        },
        {
          key: 4,
          value: 'onboarding:preferNotToSay',
          w: '50%',
        },
      ],
      copy: 'onboarding:inputProfilingGender',
    },
    {
      name: 'declaredCountry',
      type: 'Select',
      validation: 'required',
      numColumns: [2],
      options: [
        {
          key: 'onboarding:selectNationality',
          value: 'onboarding:selectNationality',
          w: '50%',
        },
        {
          key: 'onboarding:selectNationalityOther',
          value: 'onboarding:selectNationalityOther',
          w: '50%',
        },
        null,
      ],
      copy: 'onboarding:inputProfilingNationality',
    },
    {
      name: 'hasChildren',
      type: 'Select',
      grapqhType: 'boolean',
      numColumns: [3],
      options: [
        {
          key: 1,
          value: 'onboarding:selectChildrenYes',
          w: '25%',
        },
        {
          key: 0,
          value: 'onboarding:selectChildrenNot',
          w: '25%',
        },
        {
          key: 3,
          value: 'onboarding:preferNotToSay',
          w: '50%',
        },
      ],
      copy: 'onboarding:inputProfilingChildren',
    },
    {
      name: 'occupation',
      type: 'Select',
      validation: 'required',
      numColumns: [2, 2],
      options: [
        {
          key: 1,
          value: 'onboarding:selectOccStudent',
          w: '50%',
        },
        {
          key: 2,
          value: 'onboarding:selectOccWork',
          w: '50%',
        },
        {
          key: 3,
          value: 'onboarding:studyAndWork',
          w: '50%',
        },
        {
          key: 4,
          value: 'onboarding:selectOccNone',
          w: '50%',
        },
      ],
      copy: 'onboarding:inputProfilingOccupation',
    },
    {
      name: 'workDep',
      type: 'Select',
      validation: 'required',
      dependency: 'occupation == 2',
      numColumns: [3, 2, 2, 3],
      options: [
        {
          key: 'onboarding:selectCareerRH',
          value: 'onboarding:selectCareerRH',
          w: '30%',
        },
        {
          key: 'onboarding:selectCareerFinance',
          value: 'onboarding:selectCareerFinance',
          w: '35%',
        },
        {
          key: 'onboarding:selectCareerLog',
          value: 'onboarding:selectCareerLog',
          w: '35%',
        },
        {
          key: 'onboarding:selectCareerMarketing',
          value: 'onboarding:selectCareerMarketing',
          w: '50%',
        },
        {
          key: 'onboarding:selectCareerProduction',
          value: 'onboarding:selectCareerProduction',
          w: '50%',
        },
        {
          key: 'onboarding:selectCareerCS',
          value: 'onboarding:selectCareerCS',
          w: '50%',
        },
        {
          key: 'onboarding:selectCareerAccount',
          value: 'onboarding:selectCareerAccount',
          w: '50%',
        },
        {
          key: 'onboarding:selectCareerSales',
          value: 'onboarding:selectCareerSales',
          w: '35%',
        },
        {
          key: 'onboarding:selectCareerManagement',
          value: 'onboarding:selectCareerManagement',
          w: '35%',
        },
        {
          key: 'onboarding:selectCareerOther',
          value: 'onboarding:selectCareerOther',
          w: '30%',
        },
      ],
      copy: 'onboarding:inputProfilingWorkDep',
    },
    {
      name: 'workDeepOther',
      type: 'String',
      validation: 'required',
      dependency: 'workDep == Otro',
      key: 'workDep',
      copy: 'onboarding:inputProfilingWorkDeepOther',
    },
    {
      name: 'salaryIndicator',
      type: 'Select',
      validation: 'required',
      dependency: 'occupation == 2',
      numColumns: [3, 2],
      options: [
        {
          key: 1,
          value: 'onboarding:selectSalaryLess',
          w: '30%',
        },
        {
          key: 2,
          value: 'onboarding:selectSalary11',
          w: '35%',
        },
        {
          key: 3,
          value: 'onboarding:selectSalary25',
          w: '35%',
        },
        {
          key: 4,
          value: 'onboarding:selectSalaryMore',
          w: '50%',
        },
        {
          key: 5,
          value: 'onboarding:selectSalaryNot',
          w: '50%',
        },
      ],
      copy: 'onboarding:inputProfilingSalaryRange',
    },
    {
      name: 'horarioContacto',
      type: 'Select',
      validation: 'required',
      numColumns: [4, 3],
      options: [
        {
          key: '10:00',
          value: '10:00',
          w: '25%',
        },
        {
          key: '12:00',
          value: '12:00',
          w: '25%',
        },
        {
          key: '14:00',
          value: '14:00',
          w: '25%',
        },
        {
          key: '16:00',
          value: '16:00',
          w: '25%',
        },
        {
          key: '18:00',
          value: '18:00',
          w: '25%',
        },
        {
          key: '20:00',
          value: '20:00',
          w: '25%',
        },
        {
          key: '22:00',
          value: '22:00',
          w: '25%',
        },
        {
          key: '11:00',
          value: '11:00',
          w: '25%',
        },
      ],
      copy: 'onboarding:inputProfilingContactSchedule',
    },
  ],
};

const anonymousOnBoarding = {
  firstConversation: {
    flowName: 'Intro v2',
    title: 'Intro',
    force: true,
    titleTranslate: {
      en: 'Bienvenida',
      es: 'Bienvenida',
    },
  },
  firstDiary: {
    force: true,
  },
};

const permanentTherapist = {
  name: 'Domitila Martínez Bahena',
  speciality: 'Psicoterapia Psicoanalítica',
  description:
    'Estoy aqui para ayudarte a aliviar tu sufrimiento por las pérdidas y cambiar tu vida. Tengo amplia experiencia atendiendo problemáticas como depresión, ansiedad, pérdidas afectivas, violencia, soledad,etc.',
  linkProfile: 'https://terapia.mindsurf.ai/Home/BuscarTerapeuta',
  linkSchedule:
    'https://terapia.mindsurf.ai/Terapeutas/PerfilPublico?UserName=domi.mb%40hotmail.com',
};

const phoneBook = {
  emergency: [
    {
      name: 'EmergencyLine',
      language: {
        es: 'true',
        en: 'false',
      },
      action: {
        title: {
          es: 'Linea de emergencia',
          en: 'Emergency line',
        },
        desc: {
          es: 'Llama a un terapeuta de nuestra red para recibir ayuda psicológica en una crisis. Disponible 24/7.',
          en: 'Call one of our therapists to receive psychological help during a crisis. Available 24/7.',
        },
        buttonTitle: {
          es: 'Llamar',
          en: 'Call',
        },
        onPress: 'openCallNumber',
        contacts: [
          {
            en: {
              name: '',
              phone: null,
            },
            es: {
              name: '',
              phone: '527771355517',
            },
          },
        ],
      },
    },
    {
      name: 'ChatEmergency',
      language: {
        es: 'true',
        en: 'true',
      },
      action: {
        title: {
          es: 'Chat de emergencia',
          en: 'Emergency chat',
        },
        desc: {
          es: 'Habla por chat con Mindsurf para tranquilizarte.',
          en: 'Text Mindsurf to help you calm down.',
        },
        buttonTitle: {
          es: 'Chatear',
          en: 'Chat',
        },
        onPress: 'goToChat',
        contacts: [
          {
            item: 'Chat',
          },
        ],
      },
    },
    {
      name: 'ServiceEmergency',
      language: {
        es: 'true',
        en: 'true',
      },
      action: {
        title: {
          es: 'Servicios de emergencia',
          en: 'Suicide and Crisis Lifeline',
        },
        desc: {
          es: 'Si estás en una situación de peligro, llama al servicio que corresponda.',
          en: 'If you’re in danger, call the Suicide and Crisis Lifeline.',
        },
        buttonTitle: {
          es: 'Llamar',
          en: 'Call',
        },
        onPress: 'openCallNumber',
        contacts: [
          {
            en: {
              name: '988',
              phone: '988',
            },
            es: {
              name: '911',
              phone: '911',
            },
          },
        ],
      },
    },
  ],
};

const pricingPlans = {
  headerTitle: 'Planes de bienestar',
  headerImage:
    'https://res.cloudinary.com/dnmco0j2g/image/upload/v1652466399/subscriptions_jl6m7d.png',
  showCurrentPlan: true,
  plans: [
    {
      title: {
        es: 'Plan ilimitado',
      },
      active: true,
      items: [
        {
          type: 'text',
          text: {
            es: 'Descubre todo lo que te ofrecemos para aumentar tu bienestar.',
          },
        },
        {
          type: 'text',
          text: {
            es: 'Acceso ilimitado a todos los contenidos, ejercicios, herramientas y programas.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'text',
          text: {
            es: 'Asistencia psicológica 24/7 en crisis emocionales a través de una línea teléfonica de apoyo.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'text',
          text: {
            es: 'Sesiones mensuales en grupos de apoyo.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'button',
          text: {
            es: '$ 99 al mes',
          },
          planCode: {
            iOS: 'unlimited_monthly_suscription',
            android: 'unlimited_monthly_suscription',
          },
          price: 99,
        },
        {
          type: 'button',
          text: {
            es: '$ 599 anual',
            en: '$ 599 anual',
          },
          bottomBadge: {
            es: '60 % de descuento',
          },
          planCode: {
            iOS: 'unlimited_annual_suscription',
            android: 'unlimited_annual_suscription',
          },
          price: 599,
        },
      ],
      planCode: 'UNLIMITED',
    },
    // Chat therapy
    {
      title: {
        es: 'Plan con acompañamiento',
      },
      // active: false,
      items: [
        {
          type: 'text',
          text: {
            es: 'Comienza tu camino al bienestar con la experiencia más completa.',
          },
        },
        {
          type: 'text',
          text: {
            es: 'Cita virtual con especialista para orientación psicológica.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'text',
          text: {
            es: 'Acceso ilimitado a todos los contenidos, ejercicios, herramientas y programas.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'text',
          text: {
            es: 'Asistencia psicológica 24/7 en crisis emocionales a través de una línea teléfonica de apoyo.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'text',
          text: {
            es: 'Acompañamiento y orientación psicológica por whatsapp.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'button',
          text: {
            es: '$ 299 al mes',
          },
          planCode: {
            url: 'https://mindsurf.ai/terapiaporwhatsapp',
          },
          price: 299,
        },
      ],
      planCode: 'UNLIMITED_CHAT_THERAPY',
    },
    {
      title: {
        es: 'Plan gratuito',
      },
      // Backward compatibility tests
      // active: true,
      items: [
        {
          type: 'text',
          text: {
            es: 'Conoce mindsurf y comienza a mejorar tu bienestar',
          },
        },
        {
          type: 'text',
          text: {
            es: 'Acceso permanente al Diario emocional.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'text',
          text: {
            es: 'Acceso a evaluaciones y reportes de ansiedad, estrés y otros padecimientos.',
          },
          icon: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1650321383/jenny_img/PricingCheck_hazg6s.png',
        },
        {
          type: 'button',
          text: {
            es: 'Cambiar a plan gratuito',
          },
          planCode: 'FREE',
        },
      ],
      planCode: 'FREE',
    },
  ],
};

const zoomConfig = {
  clientKey: 'bOdhgeOWB74CUQ8SInTIrFuaH3xdxiiGm6UG',
  clientSecret: 'rabHjjMASjgfMHt4vfGO57HARJFmBKH6OZwS',
  domain: 'zoom.us',
};

const audioConfig = {
  finishPercentage: 95,
};

const chatConfig = {
  wordsPerMinute: 260,
};

const gpt3Diary = {
  title: {
    es: 'Platica conmigo, estoy para ti',
    en: 'Chat with me',
  },
  firstMessageShowedInHome: {
    es: '¿Necesitas hablar o desahogarte?',
    en: 'Tell me, what brings you here today?',
  },
  titleHeader: {
    es: 'Asistente emocional',
    en: 'Emotional assistant',
  },
  explanation: {
    title: {
      es: 'Asistente emocional',
      en: 'Emotional assistant',
    },
    description: {
      es: 'Soy una inteligencia artificial supervisada por expertos en salud mental. Tus comentarios me ayudarán a mejorar el apoyo que puedo ofrecerte.',
      en: "I'm an artificial intelligence supervised by mental health experts. Your feedback will help me improve the support I can offer you.",
    },
  },
  feedback: {
    title: {
      es: '¿En qué puedo mejorar?',
      en: 'What can I improve on?',
    },
    afterTitle: {
      es: 'Gracias',
      en: 'Thank you',
    },
    afterDescription: {
      es: 'Con tus comentarios podré mejorar y te daré un apoyo más adecuado.',
      en: 'With your comments I will be able to improve and give you more appropriate support.',
    },
  },
  conversationFlowName: 'Diario emocional AI',
};

const storeUrls = {
  ios: 'https://apps.apple.com/us/app/mindsurf/id1599357308',
  android:
    'https://play.google.com/store/apps/details?id=com.jennyappv2&hl=es_MX',
};

const webConfig = {
  conversationFlowOnBoarding: 'Intro Web',
  conversationFlowDefault: 'Web V2',
  conversationFlowEmployeeOnBoarding: 'Intro para Yangfen',
  conversationFlowEmployeeDefault: 'NOM-035',
  menu: [
    {
      title: 'Perfil',
      icon: 'profile',
      action: 'profile',
    },
    {
      title: 'Cerrar sesión',
      icon: 'logout',
      action: 'logout',
    },
    {
      title: 'Ayuda',
      icon: 'help',
      action: '/lcf Flujo de ayuda Web',
    },
    {
      title: 'Ajustes',
      icon: 'setting',
      action: '/lcf Ajustes',
    },
    {
      title: 'Nom035',
      icon: 'theme',
      action: '/lcf Flujo Web de la NOM 035',
    },
  ],
};

const conversationFlows = {diary: 'Prueba voz', gpt: 'Lang chain'};

const voiceConfig = {
  socketURL: 'https://stream.mindsurf.ai',
  streamOptions: {
    sampleRate: 16000,
    channels: 1,
    bitsPerSample: 16,
    audioSource: 6,
    bufferSize: 4096,
  },
  attempt: 10,
};

const phrasesProgram = {
  phrases: [
    {
      es: 'Cada paso que das te acerca más a la paz interior.',
      en: 'Every step you take brings you closer to inner peace.',
    },
    {
      es: 'Tú eres suficiente tal como eres. Tu valía no depende de tus logros.',
      en: 'You are enough just as you are. Your worth is not dependent on your achievements.',
    },
    {
      es: 'Recuerda que el autocuidado es una forma de amor propio.',
      en: 'Remember that self-care is a form of self-love.',
    },
    {
      es: 'Tómate un momento para respirar profundamente y recargar tus energías.',
      en: 'Take a moment to breathe deeply and recharge your energy.',
    },
    {
      es: 'El progreso no es lineal, pero cada pequeño paso cuenta.',
      en: 'Progress is not linear, but every small step counts.',
    },
    {
      es: 'Confía en ti mismo y en tu capacidad para superar los desafíos.',
      en: 'Trust yourself and your ability to overcome challenges.',
    },
    {
      es: 'Las dificultades son oportunidades para crecer y aprender.',
      en: 'Difficulties are opportunities to grow and learn.',
    },
    {
      es: 'Tu bienestar emocional es una prioridad. No tengas miedo de pedir ayuda cuando la necesites.',
      en: "Your emotional well-being is a priority. Don't be afraid to ask for help when you need it.",
    },
  ],
  emojis: ['😊', '✨', '🌟', '🌿', '🎉', '🧘‍♂️', '🌸', '🙌', '🌈', '💖'],
};

const therapyConfig = {
  timeSession: 50,
  shortDescription: {
    es: '¡Bienvenido/a a tu sesión de terapia! Con el apoyo de nuestros psicólogos capacitados, exploraremos juntos tus emociones y desafíos personales para mejorar tu bienestar emocional y alcanzar tus metas.',
    en: 'Welcome to your therapy session! With the support of our trained psychologists, we will explore your emotions and personal challenges together to improve your emotional well-being and achieve your goals.',
  },
  exercise: {
    language: {
      en: true,
      es: true,
    },
    title: {
      en: "Tips if it's your first time in therapy",
      es: 'Tips si es tu primera vez en terapia',
    },
    id: {
      en: 493,
      es: 503,
    },
  },
};

export const REMOTE_CONFIG_DEFAULT = {
  latestVersion,
  urls: URLS,
  loginTypeDefault: 'EMAIL',
  profiling,
  anonymousOnBoarding,
  permanentTherapist,
  phoneBook,
  pricingData: pricingPlans,
  pricingFlow: 'A',
  zoomConfig,
  audioConfig,
  gpt3Diary,
  chatConfig,
  storeUrls,
  webConfig,
  conversationFlows,
  voiceConfig,
  phrasesProgram,
  therapyConfig,
};
