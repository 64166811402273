/* eslint-disable react-hooks/exhaustive-deps */
import {useMutation, useQuery} from '@apollo/client';
import {useNavigation, useRoute} from '@react-navigation/native';
import {Box, Center, Pressable, ScrollView, Text} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CardExcercise from '../../components/Exercises/CardExcercise';
import Loading from '../../components/Loading';
import PrimaryButton from '../../components/PrimaryButton';
import {MODULE_NAMES} from '../../constants/codeConstants';
import {
  GET_DISPLAY_DETAIL_BY_ID,
  MUTATION_ADD_FAVORITE,
  MUTATION_DELETE_FAVORITE,
} from '../../graphql/queries/displayDetails';
import {
  logNavigateIntent,
  logSimpleEvent,
} from '../../helpers/analytics/fbAnalytics';
import {PushStateContext} from '../../hooks/SessionContext';
import images from '../../res/images';
import ExerciseGroupBadge from '../../components/Excercise/ExerciseGroupBadge';
import FavButton from '../../components/Excercise/FavButton';
import NavigateBar from '../../components/NavigateBar';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {isWeb} from '../../helpers/utils/utils';
import {navigateTo} from '../../navigation/MainNavigator';
import ExerciseHistoryModal from '../../components/Exercises/ExerciseHistoryModal';

/**
 * Screen de EvaluationIntro
 * Para iniciar, hagamos una pequeña evaluación para conocer cómo se encuentra tu autoestima en este momento. Nos tomará menos de 10 minutos.
 */

export default function EvaluationIntro({}) {
  const route = useRoute();
  const {id, categoryID} = route?.params;
  const {t} = useTranslation();
  const {pushState} = useContext(PushStateContext);
  const [description, setDescription] = useState('');
  const [contentUrl, setContentUrl] = useState('');
  const [title, setTitle] = useState('');
  const [isFav, setIsFav] = useState();
  const [displayDetailId, setDisplayDetailId] = useState();
  const [evaluationData, setEvaluationData] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const insets = useSafeAreaInsets();

  const {data, loading, refetch} = useQuery(GET_DISPLAY_DETAIL_BY_ID, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });
  const [addFavoriteDisplayDetail, {loading: load, error: e, data: testData}] =
    useMutation(MUTATION_ADD_FAVORITE, {
      onError: callbackAddError,
      onCompleted: callBackAddSuccess,
    });

  const [deleteFavoriteDisplayDetail, {data: dataDelete}] = useMutation(
    MUTATION_DELETE_FAVORITE,
    {
      onError: callbackDeleteError,
      onCompleted: callBackDeleteSuccess,
    },
  );

  function callbackAddError(error) {
    console.log('error add fav', error);
  }

  function callBackAddSuccess(data) {
    // editar manualmente la variable de isFav + true
    setIsFav(true);
    console.log('success add fav', data);
  }

  function callbackDeleteError(error) {
    console.log('error delete fav', error);
  }

  function callBackDeleteSuccess(data) {
    // editar manualmente la variable de isFav + fasle
    console.log('success delete fav', data);
    setIsFav(false);
  }

  const addFav = async () => {
    setIsFav(true);
    await addFavoriteDisplayDetail({variables: {displayDetailId}});
  };

  const deleteFav = async () => {
    setIsFav(false);
    await deleteFavoriteDisplayDetail({variables: {displayDetailId}});
  };

  const toggleFav = async () => {
    console.log('isFav', isFav);
    if (isFav) {
      await deleteFav();
    } else {
      await addFav();
    }
  };

  const {evaluacion} = images;

  async function handleNext() {
    await logSimpleEvent('evaluation_intro_continue', {
      program_id: id,
      name: title,
      pushState,
    });
    await logNavigateIntent(
      MODULE_NAMES.EVALUATIONS,
      {
        navigateParams: {
          name: 'Evaluacion',
          params: {
            contentUrl,
            id,
          },
        },
        from: 'EvaluationIntro',
      },
      pushState,
    );
    console.log('id?', id);
    navigateTo('Evaluacion', {
      contentUrl,
      id,
      title,
      categoryID,
      time: evaluationData?.duration,
      screenFrom: 'ExercisesCategoryEvaluations',
    });
  }

  async function back() {
    if (isWeb()) {
      navigateTo('AllTools', {id: categoryID});
      return;
    }

    await navigateTo('Home', {});
    //navigation?.goBack();
  }

  function setInfo(info) {
    setContentUrl(data?.displayDetail?.contentUrl);
    setTitle(t(info?.displayDetail?.title));
  }

  useEffect(() => {
    setDisplayDetailId(id);
    if (data && data?.displayDetail) {
      setEvaluationData(data?.displayDetail);
      setInfo(data?.displayDetail);
      setDescription(
        data?.displayDetail?.shortDescription ||
          data?.displayDetail?.description ||
          '',
      );
      const ev = data?.displayDetail?.isFavorite;
      setIsFav(ev);
    }
    if (route?.params) {
      setInfo(route?.params);
    }
  }, [data, route?.params]);

  if (loading) {
    return <Loading />;
  }

  console.log('aaaa', JSON.stringify(evaluationData, null, 2));

  return (
    <ScrollView bg={'secondary.5'} pt={isWeb() ? 20 : 3}>
      <NavigateBar
        backEvent={back}
        bg={'white'}
        props={{pt: insets?.top ? Math.min(insets.top, 10) : 5}}
        headerTitle={
          <ExerciseGroupBadge exerciseGroup={data?.displayDetail?.group} />
        }
        optionChildren={
          isWeb() ? null : <FavButton isFav={isFav} onClick={toggleFav} />
        }
      />
      <Box bg={'white'} px={5} pb={3}>
        <CardExcercise
          data={{
            ...data?.displayDetail,
            contentType: 'evaluation_v2',
            shortDescription: description,
            duration: data?.displayDetail?.duration,
          }}
          image={evaluacion}
          route={route}
        />

        {/* Ver historial */}
        {evaluationData?.records?.length > 0 && (
          <>
            <Pressable
              mt={7}
              mb={5}
              onPress={() => {
                setShowHistory(true);
              }}>
              <Text
                fontSize={'sm'}
                fontWeight={800}
                fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
                color={'primary.100'}
                textAlign={'center'}>
                {t('introex:seeHistory')}
              </Text>
            </Pressable>
            <ExerciseHistoryModal
              exerciseId={evaluationData.id}
              records={evaluationData.records}
              showModal={showHistory}
              onClose={() => {
                setShowHistory(false);
              }}
            />
          </>
        )}

        <Center flex={1} mt={3}>
          <PrimaryButton
            testId={'program-buttonStartExercise'}
            onPress={handleNext}>
            {t('program:buttonStartExercise')}
          </PrimaryButton>
        </Center>
      </Box>
    </ScrollView>
  );
}
