/* istanbul ignore file */
import {GET_FEATURE_FLAGS} from '../graphql/queries/featureFlags';
import {buildNumberWeb} from '../web.config';

const MODULES = {
  DEBUG: 'debug',
  DIARY: 'diaryEntry',
  EXERCISES: 'exercises',
  PROGRAM: 'programs',
  LIVES: 'lives',
  PROGRAM_V2: 'programsV2',
  GAMIFICATION: 'gamification',
  CHAT_AUDIO_VALIDATION: 'chatAudioValidation',
  MUTEX: 'mutex',
  CHAT_DELAY: 'chatDelay',
  LANGUAGE_SELECT: 'languageSelect',
  GRAPHQL_ERRORS: 'graphqlErrors',
  GPT_DIARY: 'gptDiary',
  DIARY_FEEDBACK: 'diaryFeedback',
  LIVES_SHOW: 'livesShow',
  ZOOM_INTEGRATION_LT_VERSION: 'zoomIntegrationLTVersion',
  DEVELOPER_MODE: 'developerMode',
  CHAT_AUDIO: 'chatAudio',
  ASSISTANT_CF: 'assistantCF',
  VOICE_STREAMING: 'voiceStreaming',
  STREAMING_TEXT: 'streamingText',
  VOICE_STREAMING_PLAYER: 'voiceStreamingPlayer',
  VALIDATE_STREAM_INTERNET_CONNECTION: 'validateStreamInternetConnection',
  RAITING_EXERCISE: 'raitingExercise',
  PROFILE_THERAPIST: 'profileTherapist',
  FORCE_SCREEN: 'forceScreen',
};

const FLAGS_DEFAULT = {
  debug: false,
  diaryEntry: true,
  exercises: true,
  programs: true,
  lives: false,
  programsV2: false,
  gamification: false,
  chatAudioValidation: false,
  chatDelay: false,
  mutex: false,
  languageSelect: false,
  graphqlErrors: false,
  livesShow: false,
  gptModule: false,
  programsModule: false,
  gptDiary: false,
  zoomIntegrationLTVersion: false,
  // zoomIntegrationMaxVersion: false. 12.0
  developerMode: false,
  chatAudio: false,
  assistantCF: null,
  voiceStreaming: false,
  streamingText: false,
  voiceStreamingPlayer: true,
  validateStreamInternetConnection: false,
  raitingExercise: false,
  profileTherapist: false,
  forceScreen: true,
};

/**
 * Regresa si el modulo esta activo o no
 * @param {*} moduleName
 * @param {*} flags
 * @returns Boolean
 *
 {
 diaryEntry: true,
 exercises: true,
 programs: true,
 lives: true,
 programsV2: false
 }
 */

function isModuleActive(moduleName, flags) {
  const flag = flags[moduleName];
  return flag;
}

async function getFlags(deviceInfo, clientGraphql) {
  const so = deviceInfo?.deviceInfo?.OS;
  let build =
    so === 'web' ? buildNumberWeb : deviceInfo?.deviceInfo?.buildNumber;
  try {
    return clientGraphql
      .query({
        query: GET_FEATURE_FLAGS,
        variables: {
          so,
          buildNumber: build,
        },
      })
      .then(r => {
        if (!r?.data?.featureFlags?.flags) return FLAGS_DEFAULT;
        const queryFlags = JSON.parse(r?.data?.featureFlags?.flags);
        return {...FLAGS_DEFAULT, ...queryFlags};
      })
      .catch(e => {
        console.log('ERROR', e);
        // No requiere reportar error
        // reportError('graphql', deviceInfo, e);
        return FLAGS_DEFAULT;
      });
  } catch (e) {
    console.log('Error en flags', e);
    return FLAGS_DEFAULT;
  }
}

function isSubscriptionFree(subscription) {
  if (
    !subscription ||
    !subscription?.plan ||
    !subscription?.plan?.externalSubscriptionIds
  ) {
    return true;
  }
  const externalSubscriptionIds = subscription?.plan?.externalSubscriptionIds;
  const isFree = externalSubscriptionIds.find(
    id =>
      id?.externalSubscriptionId?.toLowerCase() === 'free' ||
      id?.externalSubscriptionId?.toLowerCase() === 'free_trial',
  );
  return !!isFree;
}

export {isModuleActive, getFlags, MODULES, FLAGS_DEFAULT, isSubscriptionFree};
