/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Image, Text} from 'native-base';
import {Modal, Platform, TouchableOpacity} from 'react-native';
import {createChatStyles} from '../../styles/base';
import {colors} from '../../styles/colors';
import AnimatableBox from '../AnimatableBox/AnimatableBox';
import Icon from '../Icon/Icon';
import ImageViewer from '../ImageViewer/ImageViewer';

const ImageMessage = ({content, id}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const image = getImageUrl();
  const text = getText();
  const styles = createChatStyles();
  // check if is web
  const isWeb = Platform.OS === 'web';

  const stylesText = isWeb
    ? {
        ...styles.text,
        fontSize: 16,
      }
    : styles.text;

  function getImageUrl() {
    let img = content.indexOf('/***/');
    img = content.substring(img + 5);
    return img;
  }

  function getText() {
    const txt = content.split('/***/');
    return txt[0];
  }

  function showModal(img) {
    if (isWeb) {
      return;
    }
    setModalImage(img);
    setModalVisible(true);
  }

  const modalHeader = (
    <AnimatableBox animation="fadeInLeftBig" styles={styles.imageModalHeader}>
      <Icon
        name="close"
        size={30}
        color={colors.white}
        onPress={() => setModalVisible(false)}
      />
    </AnimatableBox>
  );
  return (
    <AnimatableBox
      testID={'bot-image'}
      animation="fadeInLeftBig"
      style={[styles.message, styles.bot]}>
      <TouchableOpacity
        testID={'imageMessage-button-id'}
        onPress={() => showModal(image)}>
        {text && (
          <Text testID={'image-message-text-id'} style={stylesText}>
            {text}
          </Text>
        )}
        <Image
          testID={'imageMessage-image-id'}
          source={{uri: image}}
          style={styles.multimediaSize}
          resizeMode="contain"
        />
      </TouchableOpacity>
      <Modal
        testID={'imageMessage-modal-id'}
        animationType="slide"
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}>
        <ImageViewer
          url={modalImage}
          modalHeader={modalHeader}
          onPress={() => {
            setModalVisible(false);
          }}
          styles={styles}
        />
      </Modal>
    </AnimatableBox>
  );
};

ImageMessage.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ImageMessage;
