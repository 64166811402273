import {reportError} from '../../helpers/crashlytics/fbCrashlytics';
import {FEEDBACK_LEVEL, FEEDBACK_TYPES} from '../../types';
import {database} from '../firebase/firebaseAuth';

/** Obtener  ultimos mensajes */
export const getMessages = async (uid, numberMessages, callback, time) => {
  console.log('uid', uid);
  try {
    let ref;
    if (time) {
      ref = await getMessagesWithNode(uid, time, callback);
    } else {
      ref = await getMessagesWithLimit(uid, numberMessages, callback);
    }
    await ref.once('value', snapshot => {
      console.log('valueeeee', snapshot);
      callback(snapshot);
    });
    await ref.on('child_added', snapshot => {
      console.log('child_added', snapshot);
      callback(snapshot);
    });

    return () => {
      console.log('off: value, child_added');
      ref.off('value');
      ref.off('child_added');
    };
  } catch (e) {
    console.log('🛑 Error en mensajes: ', e);
    reportError('fb', uid, 'getMessages', e);
    return false;
  }
};

const getMessagesWithLimit = async (uid, numberMessages = 6, callback) => {
  return database
    .ref(`webbot/${uid}_v2`)
    .limitToLast(numberMessages)
    .orderByChild('server_timestamp');
};

const getMessagesWithNode = async (uid, time, callback) => {
  return database
    .ref(`webbot/${uid}_v2`)
    .startAt(time)
    .orderByChild('server_timestamp');
};

/** Desconectarnos  */
export const disconnectFromDB = (uid, callback) => {
  return database.ref(`webbot/${uid}_v2`).off('value', callback);
};

/** Desconectarnos  */
export const disconnect = uid => {
  return database.ref(`webbot/${uid}_v2`).off();
};

/** Enviar comando a Engine */
export const sendCommandToEngine = (uid, command) => {
  return sendMessageToFB(uid, command, '', 'sendMessageToEngine');
};

/** Enviar mensaje a BD */
export const sendMessageToFB = (
  uid,
  content,
  lastFlowName = '',
  conversationPath = 'webbot',
  isbBot = false,
) => {
  /** ConversationPath: webbot es para mensajes normales | sendMessageToEngine: para comandos y menú  */
  const chatref = `${conversationPath}/${uid}_v2/${Date.now()}`;
  const time = Date.now();
  let ref = database.ref(chatref);

  ref
    .set({
      bot: isbBot,
      content,
      server_timestamp: time,
      flow_name: lastFlowName,
    })
    .then(() => {
      console.log('✅ Mensaje enviado a FBBBB');
    })
    .catch(error => {
      console.log('🛑 Error en sendMessageToFB: ', error);
      // TODO exception: Manejar el tipo de error e informar al usuario
      reportError('fb', {uid, content}, 'sendMessageToFB', error);
    });
  // const database = getDatabase();
  // console.log('database?.ServerValue?.TIME>> ', serverTimestamp());
  //
  // set(ref(database, chatref), {
  //   bot: isbBot,
  //   content,
  //   server_timestamp: serverTimestamp(),
  //   flow_name: lastFlowName,
  // })
  //   .then(() => {
  //     console.log('✅ Mensaje enviado a FB');
  //   })
  //   .catch(error => {
  //     console.log('🛑 Error en sendMessageToFB: ', error);
  //   });
  return time;
};

/** Verificar conexión */
export const getConnection = callback => {
  let ref = database.ref('.info/connected');
  ref
    .once('value', snapshot => {
      console.log('value', snapshot);
      callback(snapshot);
    })
    .then(() => {
      console.log('✅ Mensaje enviado a FB');
    })
    .catch(error => {
      console.log('🛑 Error en sendMessageToFB: ', error);
      // TODO exception: Manejar el tipo de error e informar al usuario
      reportError('fb', {}, 'getConnection', error);
    });
  // const db = getDatabase();
  // const connectedRef = ref(db, '.info/connected');
  // onValue(connectedRef, snapshot => {
  //   callback(snapshot.val());
  // });
};
