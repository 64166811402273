import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {MESSAGE_TYPES} from '../../constants/codeConstants';
import {getMessageType} from '../../helpers/chatUtils';
import {navigateTo} from '../../navigation/MainNavigator';
import AgreementChat from '../Agreement/AgreementChat';
import Quote from '../Quote';
import AudioMessage from './AudioMessage';
import BotMessageText from './BotMessageText';
import ConversationCard from './ConversationCard';
import EvaluationCard from './EvaluationCard';
import FeedbackPart from './FeedbackPart';
import ImageMessage from './ImageMessage';
import NoBotMessage from './NoBotMessage';
import ProgramCard from './ProgramCard';
import TherapistCard from './TherapistCard';
import VideoMessage from './VideoMessage';
import {Center, HStack} from 'native-base';
import {useEvent} from 'react-native-reanimated';

/**
 * Componente  MessageChat:
 * - No Bot
 * - Bot:
 * - Texto normal
 * - Texto con link
 * - Texto e imagen
 * - Audio ejercicio dentro de chat
 * - Ejercicio de audio
 * - Ejercicio de chat
 * - Ejercicio de video
 * - Evaluación
 * - Terapia digital
 * - contestar con voz
 * - Terapia
 *

 *
 *
 * onStartAudio
 *
 * rudder: 2.A Audio Message
 *   await sendEvent('2.A AudioMessage', {
 *           audioName: getContentUrlConfig({message: item.content})?.text,
 *         });
 *
 *
 * hasFeedback:
 * bot audio
 *
 *
 */

const MessageChat = ({
  message,
  onFinishAudio = () => {},
  onStartRecommendation = () => {},
  onFinishRecommendation = () => {},
  onFinishConversation = () => {},
  storageFeedback = () => {},
  openComment = () => {},
  recommendationHappened = false,
  chatStateResponse,
  isFeedbackShow,
  lastMessage,
  sendFeedback = () => {},
}) => {
  const animation = useRef();
  const type = getMessageType(message);

  const showFeedback =
    isFeedbackShow &&
    message?.bot &&
    (message?.isLastMessage ||
      message?.feedback?.like ||
      message?.feedback?.dislike ||
      message?.feedback?.comment);

  async function onRedirectAndClose({route, params}) {
    await navigateTo(route, params);
    onFinishConversation();
  }

  async function onStartConversation(conversation_flow_name) {
    console.log('comenzando conversacion de recomendacion');
    onStartRecommendation({type, conversationFlow: conversation_flow_name});
  }

  async function onStartVideo(dd) {
    console.log('comenzando video');
    onStartRecommendation({type, displayDetail: dd});
  }

  async function onFinishVideo(dd) {
    console.log('terminando video');
    onFinishRecommendation({type, displayDetail: dd});
  }

  async function onStartAudio(dd) {
    console.log('comenzando video');
    onStartRecommendation({type, displayDetail: dd});
  }

  // async function onFinishAudio(dd) {
  //   console.log('terminando video');
  //   onFinishRecommendation({type, displayDetail: dd});
  // }

  const {
    BOT_AUDIO,
    BOT_EXERCISE_AUDIO,
    BOT_EXERCISE_VIDEO,
    BOT_EXERCISE_CHAT,
    BOT_EVALUATION,
    BOT_DIGITAL_THERAPY,
    BOT_THERAPY,
    BOT_TEXT,
  } = MESSAGE_TYPES;

  const TypeView = {
    NO_CONTENT: () => <></>,
    NO_BOT: NoBotMessage,
    BOT_TEXT: BotMessageText,
    BOT_VIDEO: VideoMessage,
    BOT_AUDIO: AudioMessage,
    BOT_IMAGE: ImageMessage,
    BOT_EXERCISE_AUDIO: AudioMessage,
    BOT_EXERCISE_VIDEO: VideoMessage,
    BOT_EXERCISE_CHAT: ConversationCard,
    BOT_EVALUATION: EvaluationCard,
    BOT_DIGITAL_THERAPY: ProgramCard,
    BOT_VOICE: () => <></>,
    BOT_THERAPY: TherapistCard,
    AGREEMENT_SINGLE_VIEW: AgreementChat,
    QUOTE: Quote,
  };

  const DefaultView = () => <></>;
  const CurrentView = TypeView[type] ?? DefaultView;

  // switch de props
  let props = {
    ...message,
    ref: animation,
    animation: true,
    type,
    recommendationHappened,
    chatStateResponse,
    showFeedback,
    lastMessageAudio: lastMessage ? lastMessage?.id : null,
  };
  switch (type) {
    case BOT_AUDIO:
      props = {
        ...props,
        onFinishAudio,
        onStartAudio,
        sendFeedback,
      };
      break;
    case BOT_EXERCISE_CHAT:
      props = {
        ...props,
        onStartConversation,
      };
      break;
    case BOT_EXERCISE_VIDEO:
      props = {
        ...props,
        onStartVideo,
        onFinishVideo,
      };
      break;
    case BOT_THERAPY:
    case BOT_DIGITAL_THERAPY:
    case BOT_EVALUATION:
      props = {
        ...props,
        onRedirectAndClose,
      };
      break;
    default:
      break;
  }

  return (
    <>
      <HStack testID={'feedback-card'} justifyContent={'center'} mx={2}>
        <Center w={showFeedback ? '80%' : '100%'} pr={2}>
          <CurrentView {...props} />
        </Center>
        {showFeedback && (
          <Center w={'20%'}>
            <FeedbackPart
              storageFeedback={feedBackType => {
                storageFeedback({
                  feedBackType,
                  message,
                });
              }}
              id={message.id}
              feedback={message?.feedback}
              openComment={() => {
                openComment({message});
              }}
            />
          </Center>
        )}
      </HStack>
    </>
  );
};

MessageChat.propTypes = {
  message: PropTypes.object,
  onFinishConversation: PropTypes.func,
};
export default MessageChat;
