import AsyncStorage from '@react-native-async-storage/async-storage';
import {Box, Button, Center, HStack, Image, VStack} from 'native-base';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList} from 'react-native';
import {singOut} from '../../../core/firebase/firebaseAuth';
import {
  initialize,
  SessionContext,
  UserContext,
  userInitialize,
} from '../../../hooks/SessionContext';
import {navigateTo} from '../../../navigation/MainNavigator';
import images from '../../../res/images';
import {createChatStyles} from '../../../styles/base';
import AnimatableBox from '../../AnimatableBox/AnimatableBox';
import ChatMenu from '../../ChatMenu';
import LoadingSkeleton from '../../LoadingSkeleton';
import PrimaryButton from '../../PrimaryButton';
import SlidingPanel from '../../SlidingPanel/SlidingPanel';
import TypingDot from '../../TypingDot';
import MessageChat from '../MessageChat';
import ResponseBox from '../ResponseBox';
import {clearItems} from '../../../hooks/useAsyncStorageData';
import {useQuery} from '@apollo/client';
import {GET_USER_BASIC_INFO} from '../../../graphql/queries/users';
import {CHAT_MODULE, CHAT_TYPES} from '../../../constants/codeConstants';
import {getWidthAndHeight, jsRange} from '../../../helpers/utils/utils';
import {useIsFocused} from '@react-navigation/native';
import {resetRudder} from '../../../helpers/analytics/fbAnalytics';

/**
 * Componente  ChatSkeleton.native normal
 */

const ChatSkeleton = ({
  lastMessage,
  messages,
  heightModal,
  finishTextButton,
  onFinishConversation,
  onSendResponse,
  chatStateResponse,
  onStartRecommendation,
  onFinishRecommendation,
  recommendationHappened,
  showFeedback,
  chatModule,
  onStartAnswer = () => {},
  lastMessageAudio,
  sendMessageToBot,
  loadMoreMessages = () => {},
  storageFeedback = () => {},
  openComment = () => {},
  webStates,
  webNomId,
}) => {
  const {iconChat} = images;
  const chatStyles = createChatStyles();

  const {t, i18n} = useTranslation();
  const {PROGRAMV2} = CHAT_MODULE;
  const {divisor} = images;
  const fnb = !finishTextButton ? t('diary:home') : finishTextButton;

  const [menuOpen, setMenuOpen] = useState(false);

  const {setSession} = useContext(SessionContext);
  const {user, setUser} = useContext(UserContext);

  const {height, width} = getWidthAndHeight();

  /* bandera para mostrar siempre el menú para una empresa */
  const [featureFlagMenu, setFeatureFlagMenu] = useState(
    webStates?.WebAlwaysShowMenu === 'false' ? false : true,
  );
  const isFocused = useIsFocused();

  console.log('aaa', webStates?.WebAlwaysShowMenu);

  /* bandera para mandar a la pantalla de evaluaciones */
  const [featureFlagEvaluations, setFeatureFlagEvaluations] = useState(
    webStates?.WebNavigateToEvaluations === 'false' ? false : true,
  );
  const [hideOptionMenu, setHideOptionMenu] = useState(
    webStates?.WebHideOptionMenu,
  );
  const snapPoints = featureFlagMenu ? [-50, 0.2, 100, 0] : [-50, 0.5, 100, 0];

  const {data, loadingData, error, client} = useQuery(GET_USER_BASIC_INFO, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (isFocused) {
      setFeatureFlagMenu(
        webStates?.WebAlwaysShowMenu === 'false' ? false : true,
      );
    }
  }, [isFocused]);

  async function logout() {
    setFeatureFlagMenu(false);
    await client.clearStore();
    try {
      await singOut();
    } catch (e) {
      //No está logueado con Firebase
    }
    await AsyncStorage.setItem('user-language', i18n?.language);
    AsyncStorage.getItem('registerPrefix').then(async registerPrefix => {
      setSession({...initialize});
      setUser({...userInitialize});

      if (registerPrefix) {
        await clearItems([]);
        navigateTo('EmployeeLogin', {registerPrefix}, true);
        return;
      }
      await clearItems([]);
      resetRudder();
      navigateTo('Login', {reset: true});
    });
  }

  /** Show menu, Se le agrega el height dependiendo */
  function showMenu(num) {
    //const heightMenu = num === '200' ? height - num : 200;
    // menuRef.current.show(heightMenu);
    setMenuOpen(true);
  }

  const renderItem = ({item}) => {
    return (
      <MessageChat
        message={item}
        onFinishConversation={onFinishConversation}
        onFinishRecommendation={onFinishRecommendation}
        onStartRecommendation={onStartRecommendation}
        recommendationHappened={recommendationHappened}
        chatStateResponse={chatStateResponse}
        showFeedback={showFeedback}
        lastMessage={lastMessage}
        lastMessageAudio={lastMessageAudio}
        storageFeedback={storageFeedback}
        openComment={openComment}
      />
    );
  };

  const WaitingForResponse = () => {
    return (
      <AnimatableBox
        animation="fadeInLeftBig"
        // style={chatStyles.typing}
      >
        <Center p={7} alignSelf={'flex-start'}>
          <TypingDot />
        </Center>
      </AnimatableBox>
    );
  };

  const FinishButton = ({disabled}) => {
    /*if (type !== PROGRAMV2) {
          return (
            <Center m={2} my={4}>
              <Image source={divisor} resizeMode={'contain'} alt={'divisor'} />
              <Button
                w={'100%'}
                onPress={onEndConversation}
                rounded={'full'}
                py={4}
                disabled={disabled}
                borderColor={disabled ? 'secondary.5' : 'secondary.10'}
                bg={disabled ? 'secondary.5' : 'primary.100'}
                _text={{
                  color: disabled ? 'secondary.40' : 'white',
                  fontWeight: 'bold',
                  fontSize: 15,
                }}
                _pressed={{bg: 'secondary.20'}}
                testID={'finish-exercise-button'}>
                {fnb}
              </Button>
            </Center>
          );
        }*/
    return (
      <Box>
        <Image source={divisor} resizeMode={'contain'} alt={'divisor'} />
        <PrimaryButton
          extraParams={{
            mb: 5,
            disabled: disabled,
          }}
          onPress={() => {
            onFinishConversation();
            onSendResponse({content: '/lcf Web V2', isCommand: true});
          }}>
          {fnb}
        </PrimaryButton>
      </Box>
    );
  };

  const DefaultView = () => <></>;

  function renderAnswers() {
    const renderFunc = {
      WAITING_FOR_RESPONSE: () => <WaitingForResponse />,
      FINISH_MESSAGE: () =>
        webStates?.WebHideFinishButton === 'true' ? null : <FinishButton />,
      EXPECTING_RESPONSE: () => (
        <ResponseBox
          lastMessage={lastMessage}
          onResponse={onResponse}
          disabled={false}
          chatModule={chatModule}
          onStartAnswer={onStartAnswer}
          hasMenu={true}
          showMenu={showMenu}
          featureFlagMenu={featureFlagMenu}
        />
      ),
    }[chatStateResponse];

    if (renderFunc === undefined) {
      return <DefaultView />;
    }

    return <>{renderFunc()}</>;
  }

  function onResponse(content, type) {
    const contentResponse = type === CHAT_TYPES.TEXT ? content : '';
    const extraParams =
      type === CHAT_TYPES.TEXT
        ? null
        : {...content, audio_path: content?.file, path: content?.path};

    onSendResponse({
      content: contentResponse,
      extraParams,
      isCommand: false,
      typeMessage: type,
    });
  }

  return (
    <Box height={featureFlagMenu ? height * 0.7 : height * 0.9} px={2}>
      <FlatList
        style={chatStyles.flat}
        inverted
        //ref={chatRef}
        data={messages}
        renderItem={renderItem}
        keyExtractor={item => (item?.id ? item?.id : item?.index)}
        onEndReached={loadMoreMessages}
        onEndReachedThreshold={0.1}
      />

      <Box w={'100%'} alignSelf={'flex-end'}>
        {renderAnswers()}
      </Box>

      <SlidingPanel
        isOpen={featureFlagMenu ? featureFlagMenu : menuOpen}
        onClose={() => {
          console.log('closeeee');
          setMenuOpen(false);
        }}
        snapPoints={snapPoints}
        disableDrag={!!featureFlagMenu}
        featureFlagMenu={featureFlagMenu}>
        <Center w={'100%'}>
          <ChatMenu
            user={user}
            show={num => showMenu(num)}
            setCommand={async c => {
              console.log('c========', c);
              //hideMenu();
              setMenuOpen(false);
              if (c === 'logout') {
                await logout();
                return;
              }
              onSendResponse({content: c, isCommand: true});
            }}
            featureFlagEvaluations={featureFlagEvaluations}
            setFeatureFlagMenu={setFeatureFlagMenu}
            webNomId={webNomId}
            hideOptionMenu={hideOptionMenu}
          />
        </Center>
      </SlidingPanel>
    </Box>
  );
};

ChatSkeleton.propTypes = {
  text: PropTypes.string,
};
export default ChatSkeleton;
