/** Servicio que aloja todos los procesos que se realizarán al inciiar la app
 * 1. Notificaciones
 * 2. Sesión
 * 3. GraphQL
 * 4. App Player
 * 5. Remote Config FB
 */
/* istanbul ignore file */
//import remoteConfig from '@react-native-firebase/remote-config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {initialize} from '../../hooks/SessionContext';
import {remoteConfigInstance} from '../firebase/firebaseAuth';
import {
  onNotificationOpenedApp,
  processNotification,
  requestPermissions,
} from '../notifications/notificationServices';
import DeviceInfo from 'react-native-device-info';
//import * as Sentry from '@sentry/react-native';
import * as _ from 'lodash';
import {Platform} from 'react-native';
import {bytesToGB} from '../../helpers/utils/utils';
import {REMOTE_CONFIG_DEFAULT} from '../../constants/remoteConfig';
import {reportError} from '../../helpers/crashlytics/fbCrashlytics';
//import {emitter} from '../hooks/emitter';

export const remoteConfigInitialize = () => {
  //return Promise.resolve(REMOTE_CONFIG_DEFAULT);
  return remoteConfigInstance
    .fetchAndActivate()
    .then(fetchedRemotely => {
      console.log('fetchedRemotely', fetchedRemotely);
      if (fetchedRemotely) {
        console.info('Configs were retrieved from the backend and activated.');
      } else {
        console.info(
          'No configs were fetched from the backend, and the local configs were already activated.',
        );
      }
      const latestVersion = remoteConfigInstance.getValue('latestVersion');
      const urls = remoteConfigInstance.getValue('urls');
      const loginTypeDefault =
        remoteConfigInstance.getValue('loginTypeDefault');
      const profiling = remoteConfigInstance.getValue('profilingParams');
      const anonymousOnBoarding = remoteConfigInstance.getValue(
        'anonymousOnBoarding',
      );
      const permanentTherapist =
        remoteConfigInstance.getValue('permanentTherapist');
      const phoneBook = remoteConfigInstance.getValue('phoneBook');
      const pricingPlans = remoteConfigInstance.getValue('pricing');
      const pricingFlow = remoteConfigInstance.getValue('pricingFlow');
      const zoomConfig = remoteConfigInstance.getValue('zoomConfig');
      const audioConfig = remoteConfigInstance.getValue('audioConfig');
      const chatConfig = remoteConfigInstance.getValue('chatConfig');
      const storeUrls = remoteConfigInstance.getValue('storeUrls');
      const webConfig = remoteConfigInstance.getValue('webConfig');
      const digitalTherapyConfig = remoteConfigInstance.getValue(
        'digitalTherapyConfig',
      );

      console.log('firebase config', remoteConfigInstance.getAll());
      const gpt3Diary = remoteConfigInstance.getValue('gpt3Diary');
      const subscriptionData =
        remoteConfigInstance.getValue('subscriptionData');
      //
      const JsonParse = json => {
        try {
          return JSON.parse(json);
        } catch (error) {
          console.warn(json);
          console.error('remoteConfig JSON.parse', error);
          reportError(
            'initializeService.web',
            {extra: {json}},
            'JsonParse',
            error,
          );
          return undefined;
        }
      };
      return {
        latestVersion: JsonParse(latestVersion.asString()),
        urls: JsonParse(urls.asString()),
        loginTypeDefault: loginTypeDefault.asString(),
        profiling: profiling?.asString
          ? JsonParse(profiling.asString()).profile
          : null,
        anonymousOnBoarding: JsonParse(anonymousOnBoarding.asString()),
        permanentTherapist: JsonParse(permanentTherapist.asString()),
        phoneBook: JsonParse(phoneBook.asString() || null),
        pricingData: JsonParse(pricingPlans.asString()),
        pricingFlow: pricingFlow.asString(),
        // zoomConfig: JsonParse(zoomConfig.asString() || null),
        // audioConfig: JsonParse((audioConfig && audioConfig.asString()) || null),
        chatConfig: JsonParse((chatConfig && chatConfig.asString()) || null),
        storeUrls: JsonParse((storeUrls && storeUrls.asString()) || null),
        gpt3Diary: JsonParse((gpt3Diary && gpt3Diary?.asString()) || null),
        subscriptionData: JsonParse(
          (subscriptionData && subscriptionData?.asString()) || null,
        ),
        digitalTherapyConfig: JsonParse(
          (digitalTherapyConfig && digitalTherapyConfig.asString()) || null,
        ),
        webConfig: JsonParse((webConfig && webConfig.asString()) || null),
      };
    })
    .catch(error => {
      console.error('remoteConfig fetch', error);
      //Sentry.captureMessage(error, {extra: {remoteConfig: 'fetch'}});
    })
    .then(config => {
      //console.log('firebase config', config);
      const defaultConfig = {
        ...REMOTE_CONFIG_DEFAULT,
        profiling: REMOTE_CONFIG_DEFAULT.profiling?.profile,
      };

      const _config = _.defaults(
        _.mapValues(config || {}, item => (item === null ? undefined : item)),
        defaultConfig,
      );

      const _webConfig = _.defaults(
        _.mapValues(_config.webConfig || {}, item =>
          item === null ? undefined : item,
        ),
        defaultConfig.webConfig,
      );

      const finalConfig = {
        ..._config,
        webConfig: _webConfig,
        urls: {
          ..._config.urls,
          // android emulatorr
          // apollo: 'http://10.0.2.2:8000/graphql/',
          // iOS emulator
          // apollo: 'http://localhost:8000/graphql/',
        },
      };
      //console.log('remoteConfig: final', finalConfig);
      return finalConfig;
    });
};

export const getSession = async () => {
  console.log('web getSession');
  const session = await AsyncStorage.getItem('session');
  return session ? JSON.parse(session) : initialize;
};

export const getUser = async () => {
  console.log('web getUser');
  const user = await AsyncStorage.getItem('user');
  return user ? JSON.parse(user) : null;
};

export const notificationInitialize = async updateDeviceInfo => {
  console.log('web notificationInitialize');
  // Try to get token for notification
  let token = null;
  try {
    token = await requestPermissions();
  } catch (e) {
    console.log('error de request permisos', e);
    reportError(
      'initializeService.web',
      {extra: {notification: 'requestPermissions'}},
      'notificationInitialize',
      e,
    );
  }
  // //console.log('notificationInitialize', token);
  const uniqueId = DeviceInfo.getUniqueId();
  const basicDeviceInfo = {
    uniqueId,
    OS: Platform.OS,
    brand: DeviceInfo.getBrand(),
    buildNumber: DeviceInfo.getBuildNumber(),
    readableVersion: DeviceInfo.getReadableVersion(),
    haveCompleteInfo: false,
  };

  const deviceInfoPromises = [
    DeviceInfo.getCarrier(),
    DeviceInfo.getFontScale(),
    DeviceInfo.getTotalMemory(),
    DeviceInfo.getMaxMemory(),
    DeviceInfo.getUserAgent(),
    DeviceInfo.isEmulator(),
    DeviceInfo.getInstallerPackageName(),
    DeviceInfo.getTags(),
  ];
  Promise.all(deviceInfoPromises)
    .then(
      ([
        carrier,
        fontScale,
        totalMemory,
        maxMemory,
        userAgent,
        isEmulator,
        installerPackageName,
        tags,
      ]) => {
        const totalMemoryGB = bytesToGB(totalMemory);
        const maxMemoryGB = bytesToGB(maxMemory);
        return {
          carrier,
          fontScale,
          totalMemory,
          totalMemoryGB,
          maxMemory,
          maxMemoryGB,
          userAgent,
          isEmulator,
          installerPackageName,
          tags,
        };
      },
    )
    .catch(error => {
      console.error('device info promises error', error);
      updateDeviceInfo({
        uniqueId,
        fcmToken: token,
        deviceInfo: basicDeviceInfo,
      });
      /*Sentry.captureException(error, {
              extra: {deviceInfo: 'device info promises error'},
            });*/
      return {};
    })
    .then(_deviceInfo => {
      const deviceInfo = {
        ...basicDeviceInfo,
        ..._deviceInfo,
        uniqueId,
        deviceId: DeviceInfo.getDeviceId(),
        version: DeviceInfo.getVersion(),
        isTablet: DeviceInfo.isTablet(),
        systemVersion: DeviceInfo.getSystemVersion(),
        haveCompleteInfo: true,
      };
      updateDeviceInfo({uniqueId, fcmToken: token, deviceInfo: deviceInfo});
    });

  await onNotificationOpenedApp(async remoteMessage => {
    await AsyncStorage.setItem('push', JSON.stringify(true));
    //emitter.emit('pushState', {modoPush: true, data: remoteMessage.data});
    console.info('onNotificationOpenedApp', JSON.stringify(remoteMessage));
    await processNotification(remoteMessage.data);
  });

  // getInitialNotification(async remoteMessage => {
  //   if (remoteMessage !== null && remoteMessage !== undefined) {
  //     await AsyncStorage.setItem('push!', JSON.stringify(true));
  //     processNotification(remoteMessage.data);
  //   }
  //   // console.info('getInitialNotification', JSON.stringify(remoteMessage));
  // });
};
