import React from 'react';
import {Text, Center, VStack, HStack, Pressable} from 'native-base';
import PropTypes from 'prop-types';
import {FEEDBACK_TYPES} from '../../types';
import LikeIcon from '../icons/LikeIcon';
import DislikeIcon from '../icons/DislikeIcon';
import ChatMessageIcon from '../icons/ChatMessageIcon';
import {colors} from '../../styles/colors';
import {getWidthAndHeight} from '../../helpers/utils/utils';

/**
 * Componente  FeedbackPart.native normal
 */

const FeedbackPart = ({
  activeButton,
  storageFeedback,
  setSelectedMessageIds,
  message,
  setActiveButton,
  feedback,
  openComment,
}) => {
  const {width} = getWidthAndHeight();
  // get 8% of the screen width
  const width10 = width * 0.08;
  // get 80% of the width10
  const width80 = width10 * 0.6;

  const stylePressable = {
    icon: colors.white,
    circle: 'secondary.100',
  };

  const styleUnPressable = {
    icon: colors.secondary100,
    circle: 'transparent',
  };

  const ButtonCircle = ({children, onPress, pressed}) => {
    const style = pressed ? stylePressable : styleUnPressable;
    return (
      <Pressable
        onPress={onPress}
        bg={style.circle}
        w={width10}
        h={width10}
        flex={1}
        justifyContent={'center'}
        alignContent={'center'}
        borderRadius={'full'}>
        {children}
      </Pressable>
    );
  };

  return (
    <VStack flex={1} justifyContent={'center'} alignItems={'center'} space={1}>
      <HStack space={1} w={'100%'}>
        <Center
          w={'50%'}
          style={{
            display:
              activeButton !== null && activeButton !== 0 ? 'none' : 'flex',
          }}>
          <ButtonCircle
            onPress={() => {
              storageFeedback(FEEDBACK_TYPES.LIKE); //setFeedback({...feedback, like: !feedback.like})
              setSelectedMessageIds(selectedMessageIds => [
                ...selectedMessageIds,
                message.id,
              ]);
              setActiveButton(0);
            }}
            pressed={feedback.like}>
            <LikeIcon
              w={width80}
              h={width80}
              colour={
                feedback.like ? stylePressable.icon : styleUnPressable.icon
              }
            />
          </ButtonCircle>
        </Center>
        <Center
          w={'50%'}
          style={{
            display:
              activeButton !== null && activeButton !== 1 ? 'none' : 'flex',
          }}>
          <ButtonCircle
            onPress={() => {
              storageFeedback(FEEDBACK_TYPES.DISLIKE); //console.log('pres'
              setActiveButton(1);
              setSelectedMessageIds(selectedMessageIds => [
                ...selectedMessageIds,
                message.id,
              ]);
            }}
            pressed={feedback.dislike}>
            <DislikeIcon
              w={width80}
              h={width80}
              colour={
                feedback.dislike ? stylePressable.icon : styleUnPressable.icon
              }
            />
          </ButtonCircle>
        </Center>
      </HStack>
      <Center
        w={'50%'}
        style={{
          display:
            activeButton !== null && activeButton !== 2 ? 'none' : 'flex',
        }}>
        <ButtonCircle
          onPress={() => {
            openComment();
            setActiveButton(2);
            setSelectedMessageIds(selectedMessageIds => [
              ...selectedMessageIds,
              message.id,
            ]);
          }}
          pressed={!!feedback.comment}>
          <ChatMessageIcon
            w={width80}
            h={width80}
            colour={
              !!feedback.comment ? stylePressable.icon : styleUnPressable.icon
            }
          />
        </ButtonCircle>
      </Center>
    </VStack>
  );
};

FeedbackPart.propTypes = {
  text: PropTypes.string,
};
export default FeedbackPart;
