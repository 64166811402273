/* eslint-disable no-bitwise */
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Input,
  Pressable,
  Text,
} from 'native-base';
import React, {useEffect, useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {getWidthAndHeight} from '../../helpers/utils/utils';
import {createChatStyles} from '../../styles/base';
import {colors} from '../../styles/colors';
import AnimatableBox from '../AnimatableBox/AnimatableBox';
import SendIcon from '../icons/SendIcon';
import {FeatureFlagsContext} from '../../hooks/SessionContext';
//import InputWithAudio from './InputWithAudio';
import {CHAT_MODULE, CHAT_TYPES} from '../../constants/codeConstants';
import IconIon from '../Icon/IconIon';

/**
 *
 * Caja de respuestas del Chat
 */

const ResponseBox = ({
  lastMessage,
  onResponse,
  disabled,
  chatModule,
  hasMenu = false,
  onStartAnswer = () => {},
  showMenu,
  featureFlagMenu = false,
}) => {
  const {bot, responses, finishingMessage, expectingResponse} =
    lastMessage || {};
  const {t} = useTranslation();
  const [text, setText] = useState('');
  const {featureFlags} = useContext(FeatureFlagsContext);
  const chatAudio = featureFlags?.chatAudio;

  console.log('featureFlags', chatAudio);

  //get option Arrays
  const optionsArray =
    responses !== null
      ? responses
          ?.split('/')
          ?.filter(option => option !== 'Menú Principal' && option !== '')
      : [];

  function submitText() {
    onResponse(text.trim(), CHAT_TYPES.TEXT);
    setText('');
  }

  function getTypeStatus() {
    if (!expectingResponse) return;
    if (optionsArray?.length < 1) {
      return 'INPUT';
    }
    return 'OPTIONS';
  }

  function submitChip(option) {
    onResponse(option, CHAT_TYPES.TEXT);
    setText('');
  }

  function sendMessage() {
    submitText();
  }

  function onSentAudio(userFile) {
    onResponse(userFile, CHAT_TYPES.AUDIO);
  }

  const {width} = getWidthAndHeight();

  const chatStyles = createChatStyles();

  function InputResponse() {
    return (
      <HStack
        testID={'responseBox-input-id'}
        w={hasMenu ? '90%' : '100%'}
        px={5}
        justifyContent={'center'}
        p={5}>
        <Input
          w={'80%'}
          value={text}
          testID={'response-input'}
          onChangeText={setText}
          onFocus={() => {
            onStartAnswer();
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              sendMessage();
            }
          }}
          textAlignVertical={'center'}
          placeholderTextColor={colors.purpleGray}
          placeholder={t('chat:placeholder')}
          multiline={true}
          numberOfLines={2}
          autogrow={true}
          scrollEnabled={true}
          returnKeyType={'done'}
          variant="lila"
          py={2}
          px={4}
          rounded={'xl'}
          size="lg"
          borderColor={colors.purpleGray}
        />
        <Center>
          <Button
            onPress={sendMessage}
            disabled={disabled || text === ''}
            w={20}
            h={20}
            testID={'responseBox-button-id'}
            rounded={'full'}
            style={[
              chatStyles.backgroundButton,
              !text === '' ? chatStyles.chipDisabled : null,
            ]}
            //onPress={submitText}
          >
            <SendIcon w={'15'} h={'15'} colour={colors.secondary100} />
          </Button>
        </Center>
      </HStack>
    );
  }

  function OptionResponse() {
    return (
      <AnimatableBox
        testID={'responseBox-options-id'}
        animation="fadeIn"
        duration={2000}
        style={{...chatStyles.chipsContainer}}>
        <Box
          w={'95%'}
          testID={'responseBox-options-id'}
          justifyContent="flex-end"
          alignItems="flex-end"
          flexDir="column">
          <Box
            safeAreaBottom
            px={2}
            justifyContent="flex-end"
            alignItems="flex-end"
            flexDir={'row'}
            flexWrap={'wrap'}
            style={{flex: 1, minWidth: 0}}>
            {optionsArray &&
              optionsArray?.map((option, index) =>
                option !== '' ? (
                  <Pressable
                    testID={'responseBox-options-id-' + index}
                    key={index}
                    style={chatStyles.chip}
                    bg={disabled ? 'secondary.600' : 'secondary.10'}
                    disabled={disabled}
                    onPress={() => {
                      submitChip(option);
                      onStartAnswer();
                    }}>
                    <Text
                      fontFamily={'Quicksand'}
                      fontWeight={800}
                      color={disabled ? 'primary.80' : 'primary.100'}>
                      {option}
                    </Text>
                  </Pressable>
                ) : null,
              )}
          </Box>
        </Box>
      </AnimatableBox>
    );
  }

  /*function InputResponseAudio() {
      return (
        <InputWithAudio
          text={text}
          setText={setText}
          submitText={submitText}
          onStartAnswer={onStartAnswer}
          onSentAudio={onSentAudio}
        />
      );
    }*/

  const ResponseType = {
    INPUT: InputResponse,
    OPTIONS: OptionResponse,
  };

  const DefaultView = () => <></>;

  const CurrentType = ResponseType[getTypeStatus()] ?? DefaultView;

  return (
    <Center>
      <Box w={'100%'} flexDir={'row'} px={5} justifyContent={'space-between'}>
        {hasMenu && !featureFlagMenu && (
          <Center>
            <Pressable onPress={showMenu}>
              <IconIon
                onPress={showMenu}
                name={'menu'}
                size={30}
                color={colors.tertiary100}
              />
            </Pressable>
          </Center>
        )}
        <Box w={'100%'}>{CurrentType()}</Box>
      </Box>
    </Center>
  );
};

export default ResponseBox;
