function dateUTC(dateString) {
  const fechaUTC = new Date(dateString);
  return fechaUTC.toISOString();
}

async function saveCalendar(session, showMessage, errorMessage) {
  try {
    console.log('calendar services...');
  } catch (error) {
    console.error('Error:', error);
    reportError('calendarServices', {}, 'saveCalendar', error);
  }
}

export {saveCalendar, dateUTC};
