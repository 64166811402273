export default {
  update: 'Update',
  createAccount: 'Create an account before logging in',
  noRegister: "You don't have an account, but it's a great time to create one.",
  generalTitle: 'Error',
  generalDsc: 'There’s been an error processing your request.',
  later: 'There’s been an error processing your request.',
  errorBack: "An error occurred, but it's not your fault. Please try again.",
  payment: 'An error occurred while processing your payment.',
  screenNotFound: 'The screen you are looking for does not exist.',
  processInfo: 'There’s been an error processing your information.',
  load: 'There’s been an error loading your information.',
  generic: 'There’s been an error processing your request.',
  codeError: 'The code you wrote is incorrect. Please try again.',
  loginInvalid: 'The email or password is incorrect.',
  employeeLoginInvalid: 'The data is incorrect.',
  passwordInvalid:
    'There is an error in the email or password. Please try again.',
  emailInvalid: 'Check the email address. There seems to be an error.',
  signinEmail: 'An error occurred while trying to create your account.',
  invalidCode: 'The verification code you entered is invalid.',
  signInAnonymous:
    'I couldn’t find an account matching the login info you entered. Please create an account.',
  invalidNumber: 'Please check the phone number. It appears to be incorrect.',
  enterpriseCode:
    'The access code or insurance number you entered is not valid. Please, verify this information or get in touch with your provider.',
  invalidEmail: 'I was unable to verify your email address',
  therapyError:
    'An error occurred while confirming your appointment; please try again.',
  paymentError:
    'An error occurred while processing your payment; please try again.',
};
