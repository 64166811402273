const url = './images/';

const images = {
  appIcon: require(`${url}new_icons/app-icon.png`),
  splashScreenLogo: require(`${url}new_icons/splash-screen-logo.png`),
  topBarLogo: require(`${url}new_icons/top-bar-logo.png`),
  whiteLogo: require(`${url}new_icons/white-logo.png`),
  iconBlack: require(`${url}mindsurfIconBlack.png`),
  menuDots: require(`${url}menu_dots.png`),
  backArrow: require(`${url}back_arrow.png`),
  isotypeN: require(`${url}Isotipo_Mindsurf_Negativo01.png`),
  isotypeP: require(`${url}Isotipo_Mindsurf_Positivo01.png`),
  logoN: require(`${url}Logotipo_Mindsurf_Negativo01.png`),
  logoP: require(`${url}Logotipo_Mindsurf_Positivo01.png`),
  logoG: require(`${url}Logotipo_Gris.png`),
  cyanMS: require(`${url}Cyan_MS.png`),
  pinkMS: require(`${url}Pink_MS.png`),
  yellowMS: require(`${url}Yellow_MS.png`),
  autoestimaP: require(`${url}Autoestima_BM.png`),
  alert: require(`${url}Alerta_BM.png`),
  achieve: require(`${url}Logro_BM.png`),
  mountain: require(`${url}Montana_BM.png`),
  diario: require(`${url}Diario_BM.png`),
  messages: require(`${url}DiarioMSG_BM.png`),
  evaluacion: require(`${url}Evaluacion_BM.png`),
  onboarding01: require(`${url}onboarding-01.png`),
  onboarding02: require(`${url}onboarding-02.png`),
  onboarding03: require(`${url}onboarding-03.png`),
  evaluationExample: require(`${url}ejemploEvaluacion.png`),
  ejemploReporte: require(`${url}ejemplo-reporte.png`),
  terapeuta: require(`${url}terapeuta.jpeg`),
  circle_bg: require(`${url}circle_bg.png`),
  divisor: require(`${url}divisor.png`),
  wave_bg: require(`${url}wave_bg.png`),
  wave_bg_larga: require(`${url}wave-bg-larga.png`),
  wave_positive: require(`${url}wave-positive.png`),
  wave_normal: require(`${url}wave-normal.png`),
  wave_negative: require(`${url}wave-negative.png`),
  wave_gris: require(`${url}wave-gris.png`),
  wave_morada: require(`${url}wave-morada.png`),
  ansiedad: require(`${url}ansiedad.png`),
  autoestima: require(`${url}autoestima.png`),
  estres: require(`${url}estres.png`),
  inteligencia: require(`${url}inteligencia.png`),
  relaciones: require(`${url}relaciones.png`),
  terapia: require(`${url}terapia.png`),
  terapeuta2: require(`${url}terapeuta2.jpg`),
  audioIcon: require(`${url}Audio_Icono.png`),
  chatIcon: require(`${url}Chat_Icono.png`),
  tools_active: require(`${url}Herramientas_Active.png`),
  tools_inActive: require(`${url}Herramientas_InActive.png`),
  home_active: require(`${url}Inicio_Active.png`),
  home_inActive: require(`${url}Inicio_InActive.png`),
  notifications_icon: require(`${url}Notificaciones_Btn.png`),
  notifications_icon_active: require(`${url}Notificaciones_active.png`),
  program_active: require(`${url}Programa_Active.png`),
  program_inActive: require(`${url}Programa_InActive.png`),
  surfer_icon: require(`${url}Surfer_Btn.png`),
  phone_icon: require(`${url}Telefono_Btn.png`),
  phone_icon_active: require(`${url}Telefono_active.png`),
  videoIcon: require(`${url}Video_Icono.png`),
  //therapy_active: require(`${url}Terapia_Active.png`),
  therapy_inActive: require(`${url}Terapia_InActive.png`),
  meditation: require(`${url}meditacion-1.jpeg`),
  surf_active: require(`${url}surf_active.png`),
  surf_inActive: require(`${url}surf_inactive.png`),
  lives_banner: require(`${url}lives-banner.png`),
  meditaciones: require(`${url}meditacion.png`),
  insignia: require(`${url}Insignia-ejemplo.png`),
  check_white_button: require(`${url}check_white_button.png`),
  check_primary: require(`${url}check_primary.png`),
  exampleProgram: require(`${url}Ansiedad_BM.png`),
  secureIcon: require(`${url}secure_icon.png`),
  logoChat: require(`${url}LogoChat.png`),
  circlePurple: require(`${url}circlePurple.png`),
  loading: require(`${url}Loading.png`),
  sendIcon: require(`${url}send.png`),
  emotionIcon: require(`${url}emoji-emotion.png`),
  iconChat: require(`${url}icon-chat.png`),
  iconTest: require(`${url}icon.png`),
  emotionHappy: require(`${url}emotionHappy.png`),
  emotionSad: require(`${url}emotionSad.png`),
  emotionDisgust: require(`${url}emotionDisgust.png`),
  emotionAngry: require(`${url}emotionAngry.png`),
  emotionDontKnow: require(`${url}emotionDontKnow.png`),
  emotionSurprise: require(`${url}emotionSurprise.png`),
  emotionFear: require(`${url}emotionFear.png`),
  autoestimaIcon: require(`${url}autoestimaIcon.png`),
  diaryEntry: require(`${url}diary-entry.png`),
  diaryResult: require(`${url}diaryResult.png`),
  cancelSubs: require(`${url}cancel-example.png`),
  paper: require(`${url}paper.png`),
  back30: require(`${url}back-30.png`),
  after30: require(`${url}after-30.png`),
  therapist1: require(`${url}therapist1.png`),
  therapist2: require(`${url}therapist2.png`),
  therapist3: require(`${url}therapist3.png`),
  closeIcon: require(`${url}close.png`),
  logoMindsurf: require(`${url}logo-mindsurf.png`),
  backgroundGradient: require(`${url}backgroundGradient.png`),
};

export default images;
