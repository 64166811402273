export default {
  update: 'Actualizar',
  createAccount: 'Crea una cuenta antes de iniciar sesión',
  noRegister: 'No tienes una cuenta, pero es un gran momento para crearla.',
  generalTitle: '¡Error!',
  generalDsc: 'Ha ocurrido un error',
  later: 'Ha ocurrido un error, por favor intenta más tarde',
  errorBack: 'Ocurrió un error, pero no es tu culpa. Intenta de nuevo.',
  payment: 'Ha ocurrido un error al procesar tu pago.',
  screenNotFound: 'Pantalla no encontrada',
  processInfo: 'Ha ocurrido un error al procesar tu información',
  load: 'Ha ocurrido un error al cargar tu información.',
  generic: 'Ha ocurrido un error al procesar tu información',
  codeError: 'El código que escribiste no es correcto. Intenta de nuevo.',
  loginInvalid: 'El correo o la contraseña son incorrectos.',
  employeeLoginInvalid: 'Los datos son incorrectos.',
  passwordInvalid:
    'Hay un error en el correo o en la contraseña. Inténtalo de nuevo.',
  emailInvalid: 'Revisa la dirección de correo. Parece que hay un error.',
  signinEmail: 'Ha ocurrido un error al dar de alta tu usuario',
  invalidCode: 'El código que escribiste no es correcto. Intenta de nuevo.',
  signInAnonymous:
    'No fue posible ingresar sin registro, por favor crea una cuenta',
  invalidNumber: 'Revisa el número de teléfono. Parece que hay un error.',
  enterpriseCode:
    'El código de acceso que ingresaste no es válido. Por favor revisa que sea correcto.',
  invalidEmail: 'Debes proporcionar un correo válido.',
  alreadyRegister: 'Parece que ya tienes una cuenta con nosotros.',
  therapyError:
    'Ocurrió un error al confirmar su cita; por favor intente nuevamente.',
  paymentError:
    'Ocurrió un error al procesar su pago; por favor intente nuevamente.',
};
