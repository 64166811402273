/** Muestra las diferentes opciones de temas dentro del menu del chat */
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {View} from 'react-native';
import IconIon from '../components/Icon/IconIon';
import {getUserMenuEntries} from '../core/cmsServices';
import {createChatStyles} from '../styles/base';
import Loading from './Loading';
import {useTranslation} from 'react-i18next';
import {
  Center,
  Pressable,
  Text,
  Box,
  Button,
  HStack,
  VStack,
} from 'native-base';
import {colors} from '../styles/colors';
import {useQuery} from '@apollo/client';
import {GET_USER_BASIC_INFO} from '../graphql/queries/users';
import {getWidthAndHeight} from '../helpers/utils/utils';

const MenuChips = ({onMenuButtonClick, onClickBack, userId}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const {width, height} = getWidthAndHeight();

  const [userData, setUserData] = useState(null);
  const {data, loadingData, error, client} = useQuery(GET_USER_BASIC_INFO, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (data && data?.profile) {
      setLoading(false);
      let info = [
        {
          label: 'Nombre',
          value: data?.profile?.nombres,
        },
        {
          label: 'Teléfono',
          value: data?.profile?.registrationPhone,
        },
      ];
      if (
        !data?.profile?.registrationPhone &&
        data?.profile?.email &&
        !data?.profile?.email.includes('donotsend.com')
      ) {
        info.push({
          label: 'Correo electrónico',
          value: data?.profile?.email,
        });
      }

      console.log('inffff', info);

      setUserData(info);
    }
  }, [data]);

  //Styles
  const chatStyles = createChatStyles();

  // useEffect(() => {
  //   const fecthOptions = async () => {
  //     try {
  //       const response = await getUserMenuEntries(userId);
  //       setOptions(response);
  //       setLoading(false);
  //     } catch (err) {
  //       console.log('Error al cargar los menus', err);
  //       setOptions([]);
  //       setLoading(false);
  //     }
  //   };
  //   fecthOptions();
  // }, [userId]);

  if (loading) {
    return (
      <View style={[chatStyles.container, chatStyles.margin20]}>
        <Loading />
      </View>
    );
  }

  return (
    <Box width={'100%'}>
      <Pressable onPress={onClickBack}>
        <IconIon
          onPress={onClickBack}
          name={'arrow-back-outline'}
          size={30}
          color={colors.tertiary100}
        />
      </Pressable>
      {userData.length > 0 ? (
        <Center>
          {userData.map((option, index) =>
            option.value ? (
              <VStack px={width < 430 ? 5 : 20} width={width}>
                <Text fontFamily={'Quicksand'} fontWeight={800} fontSize={'lg'}>
                  {option.label}:
                </Text>
                <Text fontSize={width < 430 ? 'md' : 'lg'}>
                  {' '}
                  {option.value}
                </Text>
              </VStack>
            ) : null,
          )}
        </Center>
      ) : (
        <Center>
          <Text fontSize={'lg'}>{t('chat:noThemes')}</Text>
        </Center>
      )}
    </Box>
  );
};

MenuChips.propTypes = {
  onMenuButtonClick: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};
export default MenuChips;
