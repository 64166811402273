export default {
  children: 'Children',
  contactAndSupport: 'Contact and support',
  contactEmergencyName: 'Emergency contact',
  contactEmergencyPhone: "Emergency contact's number",
  createAccount: {
    title: 'Create an account',
    desc: 'Safeguard your information safely.',
  },
  dathOfBirth: 'Date of Birth',
  delete: 'Delete',
  deleteAccount: 'Delete account',
  deleteAccountDsc:
    'Are you sure you want to delete your account?\n All your progress and personal data will be deleted.',
  deleteButton: 'Delete',
  editProfile: 'Edit',
  editProfileDsc: 'Fill in your information.',
  faq: 'FAQ',
  gender: 'Gender',
  hello: 'Hi, {{name}}',
  helloWithoutName: 'Hello',
  lineOfWork: 'Line of Work',
  logout: 'Log out',
  mail: 'Email',
  mailCopy: 'Copied mail',
  name: 'Name',
  nationality: 'Nationality',
  notificationChange: 'Notification settings changed',
  notificationTimeZone: 'Time zone setting changed',
  notifications: 'Notifications',
  notificationsDsc: 'Choose a time to receive daily reminders.',
  language: {
    label: 'Language preferences',
    successMessage: 'Language saved.',
    system: 'System',
  },
  occupation: 'Occupation',
  phone: 'Phone',
  privacy: 'Privacy notice',
  profile: 'Profile',
  subscription: 'Subscription',
  terms: 'Terms and conditions',
  typeUser: {
    B2C: 'Free',
    B2C_PREMIUM: 'Premium',
    B2B: 'Enterprise',
    B2B_PREMIUM: 'Premium enterprise',
  },
  emergencyLine: 'Emergency line',
  emergencyLineDesc:
    'Call one of our therapists to receive psychological help during a crisis. Available 24/7.',
  chatEmergency: 'Emergency chat',
  chatEmergencyDesc: 'Text Mindsurf to help you calm down.',
  contactEmergency: 'Emergency contact',
  contactEmergencyDesc: 'Call your emergency contact to ask for help.',
  service: 'Emergency services',
  serviceDesc: 'If you’re in danger, call the emergency services of your area.',
  call: 'Call',
  chat: 'Chat',
  newExercise: 'New exercises',
  versionApp: 'App Version',
  deleteAccountInfo:
    'We’ll delete your data, history, and all the analyses made to help you improve your wellbeing and emotional health.',
  deleteAccountPhrase:
    'Type DELETE MY ACCOUNT in the following space to confirm.',
  thanksTitle: 'Thank you for using Mindsurf',
  thanksDesc:
    'We’d love to have you back. Create a new account whenever you want and enjoy our benefits again.',
  close: 'Close',
  codeSuscription: 'Subscription code',
  codeDesc: 'Allows access to a loved one.',
  writeCode: 'Type or paste your code.',
  unlock: 'Unlock',
  codeActivated:
    'Sharing is caring! Now you have full access to the premium version.',
  codeError:
    'There’s a mistake in the code. Make sure it’s correct and try again.',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  unlockSuscription: 'Unblock the premium version.',
  benefits: 'Check your benefits.',
  adviceVersionPremium:
    'You will have access as long as you maintain the premium version.',
  adviceVersionPremiumShared:
    'You will have access as long as they maintain the premium version.',
  shareCodeDesc:
    'Copy this code and send it to the other person. They should use it on',
  unlockFree: 'Unlock for free or at a discount.',
  ownerShare: 'You share this with {{name}}. 🙌🏻',
  memberShare: 'Shared with you by {{name}}. 🙌🏻',
  codeCopy: 'Code copied',
  shareSubscription: 'Share subscription',
  userName: 'User: {{name}}',
  updateError:
    'An error occurred while updating your profile; please try again.',
};
